// ** Utils
import { http } from 'src/@core/utils/http';

// ** Config
import apiConfig from 'src/configs/api';

// ** Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Get Solutions
export const getSolutions = createAsyncThunk('pages/getSolutions', async (_params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getPagesEndpoint, {
      params: {
        page_size: 999,
        type: 'Решение',
      },
    });
    const data = response.data;

    return {
      list: data.results || [],
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Cases
export const getCases = createAsyncThunk('pages/getCases', async (_params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getPagesEndpoint, {
      params: {
        page_size: 999,
        type: 'Внедрение',
      },
    });
    const data = response.data;

    return {
      list: data.results || [],
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Set Pages Sort
export const setPagesSort = createAsyncThunk('pages/setPagesSort', async (params, { rejectWithValue }) => {
  try {
    const response = await http.post(apiConfig.setPagesSortEndpoint, params);
    const data = response.data;

    return {
      data: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Create Page
export const createPage = createAsyncThunk('pages/createPage', async (params, { rejectWithValue }) => {
  try {
    const response = await http.post(apiConfig.createPageEndpoint, {
      ...params,
    });
    const data = response.data;

    return {
      data: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Edit Page
export const editPage = createAsyncThunk('pages/editPage', async (params, { rejectWithValue }) => {
  try {
    const response = await http.patch(apiConfig.editPageEndpoint.replace(':name', params.name), params);
    const data = response.data;

    return {
      page: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Delete Page
export const deletePage = createAsyncThunk('pages/deletePage', async (params, { rejectWithValue }) => {
  try {
    const response = await http.delete(apiConfig.deletePageEndpoint.replace(':name', params.name));
    const data = response.data;

    return {
      data: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Page
export const getPage = createAsyncThunk('pages/getPage', async (params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getPageEndpoint.replace(':name', params.name));
    const data = response.data;

    return {
      page: data || {},
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Page History
export const getPageHistory = createAsyncThunk('pages/getPageHistory', async (params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getPageHistoryEndpoint.replace(':name', params.name), {
      params: {
        page: params.page || 1,
        page_size: 20,
      },
    });
    const results = response.data?.results || [];

    return {
      history: results,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Upload Photo
export const uploadPhoto = createAsyncThunk('pages/uploadPhoto', async (params, { rejectWithValue }) => {
  try {
    const fd = new FormData();
    fd.append('file', params.file);

    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    const response = await http.post(apiConfig.photoUploadEndpoint, fd, config);
    const data = response.data;

    return {
      file: data.file || null,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Upload Video
export const uploadVideo = createAsyncThunk('pages/uploadVideo', async (params, { rejectWithValue }) => {
  try {
    const fd = new FormData();
    fd.append('file', params.file);

    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    const response = await http.post(apiConfig.videoUploadEndpoint, fd, config);
    const data = response.data;

    return {
      id: data.id || null,
      file: data.file || null,
      optimizedFile: data.optimizedFile || null,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Check Document
export const checkDocument = createAsyncThunk('pages/checkDocument', async (params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.checkDocumentEndpoint, {
      params: {
        id: params.id,
      },
    });
    const data = response.data?.results?.[0];

    return {
      id: data.id || null,
      file: data.file || null,
      optimizedFile: data.optimizedFile || null,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const pagesSlice = createSlice({
  name: 'pages',
  initialState: {
    isLoadingSolutions: false,
    isLoadingCases: false,
    isLoadingPage: false,
    isLoadingPageHistory: false,
    solutions: [],
    cases: [],
    page: {},
    pageHistory: [],
    solutionsError: false,
    casesError: false,
    pageError: false,
  },
  reducers: {
    resetPage: (state) => {
      state.isLoadingPage = false;
      state.pageError = false;
      state.page = {};
    },
    resetPageHistory: (state) => {
      state.pageHistory = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSolutions.pending, (state) => {
        state.isLoadingSolutions = true;
        state.solutionsError = false;
      })
      .addCase(getSolutions.fulfilled, (state, action) => {
        state.solutions = action.payload.list;

        state.isLoadingSolutions = false;
      })
      .addCase(getSolutions.rejected, (state, action) => {
        state.isLoadingSolutions = false;

        state.solutionsError = action.payload?.detail || 'Произошла ошибка';
      });
    builder
      .addCase(getCases.pending, (state) => {
        state.isLoadingCases = true;
        state.casesError = false;
      })
      .addCase(getCases.fulfilled, (state, action) => {
        state.cases = action.payload.list;

        state.isLoadingCases = false;
      })
      .addCase(getCases.rejected, (state, action) => {
        state.isLoadingCases = false;

        state.casesError = action.payload?.detail || 'Произошла ошибка';
      });
    builder
      .addCase(getPage.pending, (state) => {
        state.isLoadingPage = true;

        state.pageError = false;
      })
      .addCase(getPage.fulfilled, (state, action) => {
        state.page = action.payload.page;

        state.isLoadingPage = false;
      })
      .addCase(getPage.rejected, (state, action) => {
        state.isLoadingPage = false;

        state.pageError = action.payload?.detail || 'Произошла ошибка';
      });
    builder
      .addCase(getPageHistory.pending, (state) => {
        state.isLoadingPageHistory = true;
      })
      .addCase(getPageHistory.fulfilled, (state, action) => {
        state.pageHistory = action.payload.history || [];

        state.isLoadingPageHistory = false;
      })
      .addCase(getPageHistory.rejected, (state) => {
        state.isLoadingPageHistory = false;
        state.pageHistory = [];
      });
  },
});

export const { resetPage, resetPageHistory } = pagesSlice.actions;

export default pagesSlice.reducer;
